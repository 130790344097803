<script>
  export let size = "100%";
  export let position = "left";
</script>

<style>
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .shanna_logo {
    background: center / contain no-repeat url(/images/shanna_logo.jpg);
    width: 100%;
    height: 100%;
  }
</style>
<!-- svelte-ignore missing-declaration -->
<div class="logo-container" style="max-width:{size};max-height:{size}">
  <div class="shanna_logo" style="background-position:{position};min-width:{size};min-height:{size};"></div>
</div>