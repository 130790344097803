<script>
  import { navigate } from "svelte-routing";

  function initPayPalButton() {
    paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'checkout',
      },
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{ "description": "FREE Fanna Shanna Pack", "amount": { "currency_code": "USD", "value": 7.95, "breakdown": { "item_total": { "currency_code": "USD", "value": 0 }, "shipping": { "currency_code": "USD", "value": 7.95 }, "tax_total": { "currency_code": "USD", "value": 0 } } } }]
        });
      },
      onApprove: function(data, actions) {
        return actions.order.capture().then(function(details) {
          navigate("/success", { replace: true })
          alert('Transaction completed by ' + details.payer.name.given_name + '!');
        });
      },
      onError: function(err) {
        console.log(err);
      }
    }).render('#paypal-button-container');
  }
</script>
<svelte:head>
  <script
    src="https://www.paypal.com/sdk/js?client-id=AXg6y8kyHGRrm4EYMBK2EQN49Z0eB_Rw0WQZnvOFhtHC9QBdTBadjUcbL5_ZkBg4gLZM2nu395hdHlkj"
    data-sdk-integration-source="button-factory"
    on:load={()=>
    initPayPalButton()
    } >
  </script>
</svelte:head>

<style>
  #smart-button-container {
    margin: 20px 0 0 0;
    max-width: 400px;
    width: 100%;
  }
</style>

<div id="smart-button-container">
  <div style="text-align: center;">
    <div id="paypal-button-container"></div>
  </div>
</div>