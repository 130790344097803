<script>
  import { Router, Route } from "svelte-routing";
  import FannaShanna from "./pages/FannaShanna.svelte";
  import Success from "./pages/Success.svelte";

  export let url = ""; //This property is necessary declaration
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Arima+Madurai&family=Baloo+2:wght@400;700&family=Londrina+Solid&family=Oswald&display=swap');

  :global(:root) {
    --header_height: 100px;
    --footer_height: 80px;
    --main_height: calc(100vh - var(--header_height) - var(--footer_height));
    --body_color: #fff;
    --bg_color: rgb(235, 253, 255);
    --text_color: #333;
    --border_color: #cacaca;
    --highlight_color: #08558B;
  }

  :global(*) {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  :global(body) {
    background-color: var(--body_color);
    color: var(--text_color);
    min-height: 100vh;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  :global(a) {
    text-decoration: none;
    color: var(--highlight_color);
  }

  :global(a:hover) {
    text-decoration: none;
    color: var(--bg_color);
    background-color: var(--highlight_color);
  }

  :global(logo a) {
    height: inherit;
    min-width: inherit;
  }

  :global(main) {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    background-color: #fff;
    padding: 0 20px;
  }
</style>
<Router url="{url}">
  <Route path="/">
    <FannaShanna />
  </Route>
  <Route path="success" component="{Success}" />
</Router>